import { DateTime } from 'luxon';
import numeral from 'numeral';
import Big from 'big.js';
import { Cloudinary } from 'cloudinary-core';
import isPlainObject from 'lodash/isPlainObject';
import { formatPhoneNumber } from 'react-phone-number-input';

import config from 'config';

const cloudinary = Cloudinary.new({
  cloud_name: config('/cloudinary/cloudName'),
  secure_distribution: true,
});

export const formatInitials = (...strs) => {
  const words = strs.join(' ').split(' ');
  return words
    .map((str) => str[0])
    .join('')
    .substr(0, 3);
};

export const formatFullName = (...strs) => strs.filter(Boolean).join(' ').trim();

export const formatAddress = ({
  address1,
  address2,
  city,
  stateProvince,
  stateProv,
  postalCode,
  country,
} = {}) =>
  [
    address1,
    address2,
    [city, stateProvince || stateProv]
      .filter(Boolean)
      .join(', ')
      .concat(postalCode ? ` ${postalCode}` : ''),
    country,
  ]
    .filter(Boolean)
    .join('\n');

export const formatPhone = (phone) => formatPhoneNumber(phone) || phone;

export const formatDate = (date, format = 'medium') => {
  const toFormat = {
    short: DateTime.DATE_SHORT,
    medium: DateTime.DATE_MED,
    long: DateTime.DATE_FULL,
  }[format];

  return toFormat
    ? DateTime.fromISO(date).toLocaleString(toFormat)
    : DateTime.fromISO(date).toFormat(format);
};

export const formatDateLong = (date) => formatDate(date, 'long');
export const formatDateShort = (date) => formatDate(date, 'short');

// REVIEW TIMEZONE HANDLING
export const formatDateTimeMedium = (date, zone = null) =>
  DateTime.fromISO(date, { zone }).toLocaleString({
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: '2-digit',
    timeZoneName: 'short',
  });

export const formatDateRange = (start, end) => {
  if (!start && !end) return null;
  if (!end) return formatDate(DateTime.fromISO(start));
  const startDate = DateTime.fromISO(start);
  const endDate = DateTime.fromISO(end);
  if (startDate > endDate) {
    throw new Error('Date range formatter: Start date must be before end date');
  }

  if (endDate.hasSame(startDate, 'day')) {
    return formatDate(start);
  }

  if (endDate.hasSame(startDate, 'month')) {
    return startDate
      .toLocaleString({ month: 'short', day: 'numeric', year: 'numeric' })
      .replace(startDate.day, `${startDate.day}-${endDate.day}`);
  }

  if (endDate.hasSame(startDate, 'year')) {
    return `${startDate.toLocaleString({
      month: 'short',
      day: 'numeric',
    })} - ${endDate.toLocaleString({ month: 'short', day: 'numeric', year: 'numeric' })}`;
  }

  return `${formatDate(startDate)} - ${formatDate(endDate)}`;
};

export const formatTimeAgo = (date, format = 'long') =>
  DateTime.fromISO(date).toRelative({
    style: format,
    unit: ['years', 'months', 'weeks', 'days', 'hours', 'minutes'],
  });

export const formatNumber = (num, format) => numeral(num ?? 0).format(format);

export const parseNumber = (num) => numeral(num).value();

// This will need to be made more robust once we need
// other currencies supported
export const formatCurrency = (num, options = {}) => {
  const opts = {
    cents: 'optional',
    abbreviateAfter: null,
    ...options,
  };

  if (opts.abbreviateAfter) {
    const n = num instanceof Big ? num : Big(num);
    if (n.gte(opts.abbreviateAfter)) return formatNumber(n, '$0[.]0a');
  }

  let centFormatter;
  if (opts.cents === 'optional') centFormatter = '[.]00';
  if (opts.cents === 'always') centFormatter = '.00';

  return formatNumber(num, `$0,0${centFormatter}`);
};

export const formatCloudinaryUrl = (publicId, options = {}) => cloudinary.url(publicId, options);

export const tokenize = (source, values) => {
  if (Array.isArray(source)) return source.map((v) => tokenize(v, values));

  if (isPlainObject(source)) {
    return Object.entries(source).reduce(
      (prev, [k, v]) => ({ ...prev, [k]: tokenize(v, values) }),
      {}
    );
  }

  if (typeof source === 'string') {
    return source.replace(/({{(\w+?)}})/g, (_, __, token) => values[token] ?? '');
  }
  return source;
};
