import { type ComponentPropsWithoutRef } from 'react';

type FormLabelProps = { isRequired?: boolean } & ComponentPropsWithoutRef<'label'>;

const FormLabel = ({ isRequired = false, children = null, ...props }: FormLabelProps) => (
  <label {...props}>
    {children} {isRequired && <span className="text-red-600">*</span>}
  </label>
);

export default FormLabel;
